import fetch from "auth/FetchInterceptor";

const estimationsService = {};

estimationsService.getEstimations = function () {
    return fetch({
        url: "/estimations/",
        method: "get",
    });
};

estimationsService.getEstimationsByUserId = function (id) {
    return fetch({
        url: "estimations/users/" + id,
        method: "get",
    });
};

estimationsService.createEstimationByUser = function (data, params) {
    return fetch({
        url: `/estimations/users/${data.userId}`,
        method: "post",
        data,
        params,
    });
};

estimationsService.createEstimation = function (data) {
    return fetch({
        url: `/estimations`,
        method: "post",
        data,
    });
};

estimationsService.updateEstimationsById = function (data, params) {
    return fetch({
        url: "/estimations/" + data.id,
        method: "patch",
        data,
        params,
    });
};

estimationsService.deleteEstimationById = function (id) {
    return fetch({
        url: "/estimations/" + id,
        method: "delete",
    });
};

export default estimationsService;
