import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import estimationsService from 'services/EstimationsService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    estimations: [],
}

export const getEstimations = createAsyncThunk(
    'estimations/getEstimationsByUserId',
    async ({ rejectWithValue }) => {
        try {
            const response = await estimationsService.getEstimations();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getEstimationsByUserId = createAsyncThunk(
    'estimations/getEstimationsByUserId',
    async (params, { rejectWithValue }) => {
        try {
            const response = await estimationsService.getEstimationsByUserId(params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const createEstimationByUser = createAsyncThunk(
    'estimations/createEstimationByUser',
    async (data, params, { rejectWithValue }) => {
        try {
            const response = await estimationsService.createEstimationByUser(data, params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const createEstimation = createAsyncThunk(
    'estimations/createEstimation',
    async (data, params, { rejectWithValue }) => {
        try {
            const response = await estimationsService.createEstimation(data, params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const updateEstimationsById = createAsyncThunk(
    'estimations/updateEstimationsById',
    async (data, params, { rejectWithValue }) => {
        try {
            const response = await estimationsService.updateEstimationsById(data, params);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const deleteEstimationById = createAsyncThunk(
    'estimations/deleteEstimationById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await estimationsService.deleteEstimationById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const estimationsSlice = createSlice({
    name: 'estimations',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getEstimationsByUserId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getEstimationsByUserId.fulfilled, (state, action) => {
                state.loading = false;
                state.estimations = action.payload;
            })
            .addCase(getEstimationsByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
})

export default estimationsSlice.reducer