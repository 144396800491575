
import {createSlice} from "@reduxjs/toolkit";
import {userExpensesApi} from "../api/useUserExpenses";

const initialState = {
    UserExpenses: {
        doYouDriveYourOwnVehicleForWork: "",
        whatVehicleDoYouDrive: "",
        howDoYouMakeYourWorkJourneys: "",
        areYouPaidAMonthlyCarAllowanceByAcme: "",
        howManyMilesDoYouExpectToDriveForWorkJourneysThisYear: "",
        howDoesAcmePayYourMileageExpenses: "",
        areYouTravellingForWorkAndNotToWork: "",
        doYouBuyFoodOrDrinkWhenTravellingForWork: "",
        howManyDaysDoYouTravelAWeekOnAverage: "",
        howMuchDoYouSpendOnFoodAndDrinkPerDayWhenTravelling: "",
        doesAcmePayYouExpensesForYourFoodAndDrink: "",
        doYouDonateAnyMoneyToGiftAidCharities: "",
        doYouPayAnyMoneyIntoAPrivatePensionScheme: "",
        doYouWearAUniformAsPartOfDoingYourJobWithAcme: "",
        doYouWorkFromHome: "",
        whyDoYouWorkFromHome: "",
        willYouEarnAnyOtherIncomeThisYear: "",
        whatTypeOfIncomeWillYouEarnThisYear: ""
    },
};


const userExpensesSlice = createSlice({
    name: 'userExpenses',
    initialState,
    reducers: {
        setUserExpenses: (state, action) => {
            state.UserExpenses = {
                ...state.UserExpenses,
                ...action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            userExpensesApi.endpoints.getUserExpensesById.matchFulfilled,
            (state, action) => {
                state.UserExpenses = {
                    ...state.UserExpenses,
                    ...action.payload,
                };
            },
        );
    }
});



export const {setUserExpenses} = userExpensesSlice.actions;
export default userExpensesSlice.reducer;
