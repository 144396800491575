
import {createSlice} from "@reduxjs/toolkit";
import {JobApi} from "../api/useJob";

const initialState = {
    job: {
        areYouPaidCommission: "",
        whatsYourEmployersName: "",
        whatsYourJobTitle: "",
        whatsYourBasicSalary: "",
        areYouPaidAnyBonuses: "",
        areYouInAcmesPensionScheme: ""
    },
};


const JobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setJob: (state, action) => {
            state.job = {
                ...state.job,
                ...action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            JobApi.endpoints.jobByIdUser.matchFulfilled,
            (state, action) => {
                state.job = {
                    ...state.job,
                    ...action.payload,
                };
            },
        );
    }
});



export const {setJob} = JobSlice.actions;
export default JobSlice.reducer;
