import {rootApi} from "./api";
export const JobApi = rootApi
    .injectEndpoints({
        overrideExisting: true,
        endpoints: build => {
            return {
                updateJobByIdUser: build.mutation({
                    query: ({id,body}) => ({
                        url: `/jobs/user/${id}`,
                        method: 'PATCH',
                        body,
                    }),
                }),
                jobByIdUser: build.query({
                    query: (idUser) => `/jobs/user/${idUser}`,
                }),
            };
        },
    })
    .enhanceEndpoints({addTagTypes: []});

export const {
    useUpdateJobByIdUserMutation,
    useLazyJobByIdUserQuery
} = JobApi
