import {rootApi} from "./api";
export const userExpensesApi = rootApi
    .injectEndpoints({
        overrideExisting: true,
        endpoints: build => {
            return {
                getUserExpensesById: build.query({
                    query: (idUser) => `user-expenses/user/${idUser}`,
                }),
                updateUserExpensesById: build.mutation({
                    query: ({ id, body }) => ({
                        url: `user-expenses/user/${id}`,
                        method: 'PATCH',
                        body,
                    }),
                }),
            };
        },
    })
    .enhanceEndpoints({addTagTypes: []});

export const {
    useUpdateUserExpensesByIdMutation,
    useLazyGetUserExpensesByIdQuery
} = userExpensesApi
