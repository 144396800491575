import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import estimations from './slices/estimationSlice'
import JobReducer from './slices/jobSlice'
import userExpensesReducer from './slices/userExpensesSlice'
import {rootApi} from "./api/api";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        [rootApi.reducerPath]: rootApi.reducer,
        theme,
        auth,
        estimations,
        job: JobReducer,
        userExpenses: userExpensesReducer,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
